div#c-application {
  div#a-index {
    margin-top: 4em;

    menu#main-menu {
      margin-top: 2em;
    }

    .font-header {
      font-size: 2.5rem;
    }
  }

    div#search-box {
      margin-bottom: 2em;

      h2 {
        display: none;
      }
    }

    .search {
      margin-top: 2em;
    }

  .landing-title {
    margin-left: 30px;
    position: relative;

    .landing-logo {
      position: absolute;
      left: -108px;
      width: 100px;
      top: -14px;
    }
  }
}

div#c-application #a-index #counter {
  margin-top: 40px;
  position: relative;
}

div#c-application #a-index #counter img {
  z-index: 1;
}

div#c-application #a-index #search-box h2 {
  display: none;
}

div#c-application #a-index #counter:after {
  display: block;
  content: '';
  width: 100%;
  height: 70px;
  background: red;
  position: fixed;
  left: 0;
  background-color: #282a2e;
  border: 1px solid #282a2e;
}
