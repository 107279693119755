#maintoggle {
  display: none;
}

header#top {
  #app-name-header {
    font-size: var(--text-xxl);
    height: 42px;
    margin-left: 30px;
    margin-right: 30px;

    #app-logo img {
      width: 1.25em;
      height: 1.25em;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  menu {
    margin-top: -2px;
    background: var(--subnav-menu-background-color);
    padding: 6px 20px;

    form {
      display: inline-block;

      input {
        width: 9.5em;
      }
    }

    li {
      margin: 0;
      padding: 0;
      white-space: nowrap;
    }

    #nav-logo img {
      height: 28px;
    }

    li a {
      padding: 6px 10px;
    }
  }

  menu.main {
    margin-top: 0;
    background: none;

    li.current a {
      background: var(--subnav-menu-background-color);
      font-weight: bold;
    }

    li#nav-login a {
      font-weight: bold;
      color: var(--login-link-color);
    }

    li.forum-updated a,
    li.reports-pending a {
      font-style: italic;
    }
  }
}
