html {
  /*
   * Generated by app/logical/color_palette.rb.
   * See https://danbooru.donmai.us/static/colors.
   *
   * <%= ColorPalette.css_rules %>
   * bin/rails runner "puts ColorPalette.css_rules"
   */

  --grey-0: #f6f6f8; /* hsluv(265, 17.5, 97.0) */
  --grey-1: #e8e8ec; /* hsluv(265, 17.5, 92.0) */
  --grey-2: #c5c8c6;
  --grey-3: #ababbc; /* hsluv(265, 17.5, 70.5) */
  --grey-4: #9192a7; /* hsluv(265, 17.5, 61.0) */
  --grey-5: #777892; /* hsluv(265, 17.5, 51.2) */
  --grey-6: #5b5c79; /* hsluv(265, 17.5, 40.0) */
  --grey-7: #3f4058; /* hsluv(265, 17.5, 28.0) */
  --grey-8: #282a2e;
  --grey-9: #1d1f21;
  --red-0: #fff4f4; /* hsluv(12, 100, 97.0) */
  --red-1: #fbe3e3; /* hsluv(12, 80, 92.0) */
  --red-2: #ffc3c3; /* hsluv(12, 100, 84.0) */
  --red-3: #ff8a8b; /* hsluv(12, 100, 70.5) */
  --red-4: #ff5a5b; /* hsluv(12, 100, 61.0) */
  --red-5: #ed2426; /* hsluv(12, 90, 51.2) */
  --red-6: #c00004; /* hsluv(12, 100, 40.0) */
  --red-7: #861011; /* hsluv(12, 90, 28.0) */
  --red-8: #5c1313; /* hsluv(12, 75, 19.0) */
  --red-9: #391313; /* hsluv(12, 50, 12.0) */
  --orange-0: #fff4f0; /* hsluv(37, 100, 97.0) */
  --orange-1: #ffe2d5; /* hsluv(37, 100, 92.0) */
  --orange-2: #ffc5a5; /* hsluv(37, 100, 84.0) */
  --orange-3: #fd9200; /* hsluv(37, 100, 70.5) */
  --orange-4: #d97c00; /* hsluv(37, 100, 61.0) */
  --orange-5: #b56700; /* hsluv(37, 100, 51.2) */
  --orange-6: #8d4f00; /* hsluv(37, 100, 40.0) */
  --orange-7: #653700; /* hsluv(37, 100, 28.0) */
  --orange-8: #482500; /* hsluv(37, 100, 19.0) */
  --orange-9: #331900; /* hsluv(37, 100, 12.0) */
  --yellow-0: #fdf6e6; /* hsluv(68, 80, 97.0) */
  --yellow-1: #f7e7c3; /* hsluv(68, 60, 92.0) */
  --yellow-2: #ead084; /* hsluv(68, 60, 84.0) */
  --yellow-3: #ccaa00; /* hsluv(68, 100, 70.5) */
  --yellow-4: #ae9100; /* hsluv(68, 100, 61.0) */
  --yellow-5: #917800; /* hsluv(68, 100, 51.2) */
  --yellow-6: #705d00; /* hsluv(68, 100, 40.0) */
  --yellow-7: #504100; /* hsluv(68, 100, 28.0) */
  --yellow-8: #382d00; /* hsluv(68, 100, 19.0) */
  --yellow-9: #271f04; /* hsluv(68, 90, 12.0) */
  --green-0: #e8fce9; /* hsluv(130, 60, 97.0) */
  --green-1: #cbf2ce; /* hsluv(130, 40, 92.0) */
  --green-2: #93e49a; /* hsluv(130, 50, 84.0) */
  --green-3: #35c64a; /* hsluv(130, 90, 70.5) */
  --green-4: #00ab2c; /* hsluv(130, 100, 61.0) */
  --green-5: #008e23; /* hsluv(130, 100, 51.2) */
  --green-6: #006e19; /* hsluv(130, 100, 40.0) */
  --green-7: #004e0f; /* hsluv(130, 100, 28.0) */
  --green-8: #003708; /* hsluv(130, 100, 19.0) */
  --green-9: #002604; /* hsluv(130, 100, 12.0) */
  --azure-0: #f1f7ff; /* hsluv(242, 100, 97.0) */
  --azure-1: #d9eaff; /* hsluv(242, 100, 92.0) */
  --azure-2: #afd5ff; /* hsluv(242, 100, 84.0) */
  --azure-3: #5f89ac;
  --azure-4: #81a2be;
  --azure-5: #0081c0; /* hsluv(242, 100, 51.2) */
  --azure-6: #006496; /* hsluv(242, 100, 40.0) */
  --azure-7: #00466b; /* hsluv(242, 100, 28.0) */
  --azure-8: #00314d; /* hsluv(242, 100, 19.0) */
  --azure-9: #002237; /* hsluv(242, 100, 12.0) */
  --blue-0: #f4f6ff; /* hsluv(257, 100, 97.0) */
  --blue-1: #e1e8ff; /* hsluv(257, 100, 92.0) */
  --blue-2: #c3d0ff; /* hsluv(257, 100, 84.0) */
  --blue-3: #8caaff; /* hsluv(257, 100, 70.5) */
  --blue-4: #5d8fff; /* hsluv(257, 100, 61.0) */
  --blue-5: #0075f8; /* hsluv(257, 100, 51.2) */
  --blue-6: #005ac3; /* hsluv(257, 100, 40.0) */
  --blue-7: #003f8d; /* hsluv(257, 100, 28.0) */
  --blue-8: #002c66; /* hsluv(257, 100, 19.0) */
  --blue-9: #001e4a; /* hsluv(257, 100, 12.0) */
  --purple-0: #f9f5ff; /* hsluv(282, 100, 97.0) */
  --purple-1: #ece5f8; /* hsluv(282, 70, 92.0) */
  --purple-2: #ddc9fb; /* hsluv(282, 90, 84.0) */
  --purple-3: #c797ff; /* hsluv(282, 100, 70.5) */
  --purple-4: #b871ff; /* hsluv(282, 100, 61.0) */
  --purple-5: #ab3fff; /* hsluv(282, 100, 51.2) */
  --purple-6: #9000df; /* hsluv(282, 100, 40.0) */
  --purple-7: #6700a1; /* hsluv(282, 100, 28.0) */
  --purple-8: #421765; /* hsluv(282, 80, 19.0) */
  --purple-9: #2f0e49; /* hsluv(282, 80, 12.0) */
  --magenta-0: #fff3ff; /* hsluv(307, 100, 97.0) */
  --magenta-1: #f8e1f8; /* hsluv(307, 70, 92.0) */
  --magenta-2: #fabefb; /* hsluv(307, 90, 84.0) */
  --magenta-3: #fd78ff; /* hsluv(307, 100, 70.5) */
  --magenta-4: #fd23ff; /* hsluv(307, 100, 61.0) */
  --magenta-5: #d700d9; /* hsluv(307, 100, 51.2) */
  --magenta-6: #a800aa; /* hsluv(307, 100, 40.0) */
  --magenta-7: #79007a; /* hsluv(307, 100, 28.0) */
  --magenta-8: #4f1450; /* hsluv(307, 80, 19.0) */
  --magenta-9: #380c39; /* hsluv(307, 80, 12.0) */

  --white: #FFFFFF;
  --black: #000000;

  --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

html, body[data-current-user-theme="light"] {
  --body-background-color: var(--white);
  --text-color: var(--black);

  --inverse-text-color: var(--white);
  --muted-text-color: var(--grey-4);
  --header-color: var(--grey-9);
  --link-color: var(--blue-5);
  --link-hover-color: var(--blue-3);

  --default-border-color: var(--grey-1);

  --error-background-color: var(--red-1);
  --success-background-color: var(--green-0);
  --target-background: var(--yellow-0);

  --subnav-menu-background-color: var(--blue-0);
  --responsive-menu-background-color: var(--blue-1);

  --form-input-text-color: var(--text-color);
  --form-input-background: var(--body-background-color);
  --form-input-border-color: var(--grey-2);
  --form-input-placeholder-text-color: var(--grey-4);
  --form-input-validation-error-border-color: var(--red-4);
  --form-input-validation-error-text-color: var(--red-5);

  --form-button-text-color: var(--text-color);
  --form-button-disabled-text-color: var(--grey-5);
  --form-button-background: var(--grey-1);
  --form-button-border-color: var(--grey-2);
  --form-button-hover-background: var(--grey-0);
  --form-button-hover-box-shadow-color: var(--grey-5);
  --form-button-active-background: var(--grey-2);

  --button-primary-text-color: var(--white);
  --button-primary-background-color: var(--link-color);
  --button-primary-hover-background-color: var(--link-hover-color);
  --button-primary-disabled-color: var(--grey-5);

  --button-outline-primary-color: var(--link-color);
  --button-outline-danger-color: var(--red-5);

  --quick-search-form-background: var(--body-background-color);

  --user-upgrade-basic-background-color: var(--blue-0);
  --user-upgrade-gold-background-color: var(--yellow-1);
  --user-upgrade-platinum-background-color: var(--blue-1);

  --table-header-border-color: var(--grey-6);
  --table-row-border-color: var(--grey-2);
  --table-row-hover-background: var(--blue-1);
  --table-even-row-background: var(--grey-0);

  --preview-pending-color: var(--blue-5);
  --preview-flagged-color: var(--red-5);
  --preview-deleted-color: var(--grey-9);
  --preview-has-children-color: var(--green-3);
  --preview-has-parent-color: var(--yellow-3);
  --preview-selected-color: rgba(0, 0, 0, 0.15);

  --preview-icon-color: var(--inverse-text-color);
  --preview-icon-background: rgba(0, 0, 0, 0.7);

  --media-asset-placeholder-background-color: var(--grey-1);

  --modqueue-tag-warning-color: var(--red-1);

  --file-upload-component-background-color: var(--body-background-color);
  --uploads-dropzone-background: var(--grey-1);
  --uploads-dropzone-progress-bar-foreground-color: var(--link-color);
  --uploads-dropzone-progress-bar-background-color: var(--link-hover-color);

  --forum-vote-up-color: var(--green-5);
  --forum-vote-meh-color: var(--orange-3);
  --forum-vote-down-color: var(--red-5);

  --forum-topic-status-new-color: var(--red-5);
  --forum-topic-status-pending-color: var(--blue-5);
  --forum-topic-status-approved-color: var(--green-5);
  --forum-topic-status-rejected-color: var(--red-5);

  --moderation-report-text-color: var(--red-5);
  --moderation-report-background-color: var(--red-1);

  --comment-sticky-background-color: var(--blue-0);

  --post-tooltip-background-color: var(--body-background-color);
  --post-tooltip-border-color: var(--grey-2);
  --post-tooltip-header-background-color: var(--blue-0);
  --post-tooltip-info-color: var(--muted-text-color);

  --post-tooltip-scrollbar-background: var(--grey-4);
  --post-tooltip-scrollbar-thumb-color: var(--grey-5);
  --post-tooltip-scrollbar-track-background: var(--grey-1);

  --user-tooltip-positive-feedback-color: var(--orange-3);
  --user-tooltip-negative-feedback-color: var(--red-5);

  --autocomplete-selected-background-color: var(--blue-1);
  --autocomplete-border-color: var(--grey-2);
  --autocomplete-tag-autocorrect-underline: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAYAAAC09K7GAAAAHElEQVQYV2NkQAL/GRj+M4IJBgY4zQhSABMEsQHMOAgCT5YN9gAAAABJRU5ErkJggg==);

  --diff-list-added-color: var(--green-5);
  --diff-list-removed-color: var(--red-5);
  --diff-list-obsolete-added-color: var(--green-7);
  --diff-list-obsolete-removed-color: var(--red-7);

  --wiki-page-versions-diff-del-background: var(--red-2);
  --wiki-page-versions-diff-ins-background: var(--green-2);

  --post-notice-border-color: var(--grey-2);
  --post-parent-notice-background: var(--green-0);
  --post-child-notice-background: var(--yellow-0);
  --post-pending-notice-background: var(--blue-1);
  --post-banned-notice-background: var(--red-1);
  --post-deleted-notice-background: var(--red-1);
  --post-resized-notice-background: var(--magenta-1);
  --post-search-notice-background: var(--grey-1);

  --post-artist-commentary-container-background: var(--grey-0);
  --post-artist-commentary-container-border-color: var(--grey-1);

  --post-upvote-color: var(--link-color);
  --post-downvote-color: var(--red-5);

  --note-body-background: #FFE;
  --note-body-text-color: var(--black);
  --note-body-border-color: var(--black);
  --note-box-border-color: var(--black);
  --note-box-shadow: 0 0 0 1px white;

  --unsaved-note-box-border-color: var(--red-4);
  --movable-note-box-border-color: var(--blue-5);
  --note-preview-border-color: var(--red-4);
  --note-preview-background: var(--note-body-background);
  --note-highlight-color: var(--blue-5);
  --note-tn-color: var(--muted-text-color);

  --series-pool-color: var(--copyright-tag-color);
  --series-pool-hover-color: var(--copyright-tag-hover-color);

  --collection-pool-color: var(--general-tag-color);
  --collection-pool-hover-color: var(--general-tag-hover-color);

  --fetch-source-data-border-color: var(--grey-2);

  --post-mode-menu-active-post-outline-color: var(--blue-2);
  --post-mode-menu-translation-background: var(--blue-2);

  --tag-count-color: var(--muted-text-color);
  --low-post-count-color: var(--red-5);

  --remove-favorite-button: var(--red-4);

  --ugoira-seek-slider-background: var(--grey-0);
  --ugoira-seek-slider-progressbar-background: var(--white);

  --keyboard-shortcut-color: var(--inverse-text-color);
  --keyboard-shortcut-background-color: var(--grey-6);

  --login-link-color: var(--azure-4);
  --footer-border-color: var(--grey-1);
  --divider-border-color: var(--grey-2);

  --jquery-ui-widget-content-background: var(--body-background-color);
  --jquery-ui-widget-content-text-color: var(--text-color);

  --notice-error-background: var(--red-1);
  --notice-error-border-color: var(--red-2);
  --notice-info-background: var(--yellow-0);
  --notice-info-border-color: var(--yellow-1);

  --dtext-blockquote-border-color: var(--grey-4);
  --dtext-code-background: var(--grey-1);
  --dtext-expand-border: 1px solid var(--grey-3);
  --dtext-spoiler-background-color: var(--text-color);
  --dtext-spoiler-hover-color: var(--inverse-text-color);

  --wiki-page-other-name-background-color: var(--grey-1);

  --paginator-arrow-background-color: var(--inverse-text-color);
  --paginator-arrow-color: var(--link-color);

  --artist-tag-color: var(--red-6);
  --artist-tag-hover-color: var(--red-5);
  --copyright-tag-color: var(--magenta-6);
  --copyright-tag-hover-color: var(--magenta-5);
  --character-tag-color: var(--green-4);
  --character-tag-hover-color: var(--green-3);
  --general-tag-color: var(--link-color);
  --general-tag-hover-color: var(--link-hover-color);
  --meta-tag-color: var(--orange-3);
  --meta-tag-hover-color: var(--orange-2);

  --user-admin-color: var(--red-5);
  --user-moderator-color: var(--character-tag-color);
  --user-builder-color: var(--copyright-tag-color);
  --user-platinum-color: var(--grey-5);
  --user-gold-color: var(--meta-tag-color);
  --user-member-color: var(--general-tag-color);
  --user-restricted-color: var(--general-tag-color);
  --user-banned-color: var(--grey-8);

  --user-verified-email-color: var(--green-4);
  --user-unverified-email-color: var(--yellow-2);

  --news-updates-background: var(--grey-0);
  --news-updates-border-color: var(--grey-1);

  --related-tags-container-background: var(--grey-0);
  --selected-related-tag-background-color: var(--link-color);
  --selected-related-tag-color: var(--inverse-text-color);

  --inactive-artist-url-color: var(--red-6);
  --inactive-artist-url-hover-color: var(--red-5);
  --banned-artist-label-color: var(--red-5);
  --deleted-artist-label-color: var(--grey-6);
}

/* variables that aren't defined in the dark theme fall back to the :root theme */
@mixin dark-theme {
  --body-background-color: var(--grey-9);
  --text-color: var(--grey-2);

  --inverse-text-color: var(--grey-0);
  --muted-text-color: var(--grey-4);
  --header-color: var(--grey-1);
  --link-color: var(--azure-4);
  --link-hover-color: var(--azure-3);

  --default-border-color: var(--grey-7);

  --error-background-color: var(--red-9);
  --success-background-color: var(--green-9);
  --target-background: var(--azure-8);

  --subnav-menu-background-color: var(--grey-8);
  --responsive-menu-background-color: var(--grey-8);

  --form-input-text-color: var(--grey-2);
  --form-input-background: var(--grey-7);
  --form-input-border-color: var(--grey-5);
  --form-input-placeholder-text-color: var(--grey-3);
  --form-input-validation-error-border-color: var(--red-4);
  --form-input-validation-error-text-color: var(--red-5);

  --form-button-text-color: var(--grey-9);
  --form-button-disabled-text-color: var(--grey-5);
  --form-button-background: var(--grey-2);
  --form-button-border-color: var(--grey-4);
  --form-button-hover-background: var(--grey-0);
  --form-button-hover-box-shadow-color: var(--grey-5);
  --form-button-active-background: var(--grey-3);

  --button-primary-text-color: var(--white);
  --button-primary-background-color: var(--link-color);
  --button-primary-hover-background-color: var(--link-hover-color);
  --button-primary-disabled-color: var(--grey-4);

  --button-outline-primary-color: var(--azure-4);
  --button-outline-danger-color: var(--red-4);

  --quick-search-form-background: var(--grey-9);

  --user-upgrade-basic-background-color: var(--grey-8);
  --user-upgrade-gold-background-color: var(--grey-7);
  --user-upgrade-platinum-background-color: var(--blue-9);

  --table-header-border-color: var(--grey-7);
  --table-row-border-color: var(--grey-7);
  --table-even-row-background: var(--grey-8);
  --table-row-hover-background: var(--grey-7);

  --preview-pending-color: var(--azure-4);
  --preview-flagged-color: var(--red-5);
  --preview-deleted-color: var(--grey-3);
  --preview-has-children-color: var(--green-3);
  --preview-has-parent-color: var(--orange-3);
  --preview-selected-color: rgba(255, 255, 255, 0.25);

  --media-asset-placeholder-background-color: var(--grey-8);

  --modqueue-tag-warning-color: var(--red-7);

  --file-upload-component-background-color: var(--grey-8);
  --uploads-dropzone-background: var(--grey-7);
  --uploads-dropzone-progress-bar-foreground-color: var(--link-color);
  --uploads-dropzone-progress-bar-background-color: var(--link-hover-color);

  --forum-vote-up-color: var(--green-4);
  --forum-vote-meh-color: var(--yellow-2);
  --forum-vote-down-color: var(--red-4);

  --forum-topic-status-new-color: var(--red-3);
  --forum-topic-status-pending-color: var(--azure-3);
  --forum-topic-status-approved-color: var(--green-3);
  --forum-topic-status-rejected-color: var(--red-3);

  --moderation-report-text-color: var(--red-4);
  --moderation-report-background-color: var(--red-9);

  --comment-sticky-background-color: var(--azure-8);

  --post-tooltip-background-color: var(--grey-8);
  --post-tooltip-border-color: var(--grey-7);
  --post-tooltip-header-background-color: var(--grey-8);
  --post-tooltip-info-color: var(--grey-2);

  --post-tooltip-scrollbar-background: var(--grey-4);
  --post-tooltip-scrollbar-thumb-color: var(--grey-5);
  --post-tooltip-scrollbar-track-background: var(--grey-1);

  --user-tooltip-positive-feedback-color: var(--yellow-1);
  --user-tooltip-negative-feedback-color: var(--red-1);

  --autocomplete-selected-background-color: var(--grey-7);
  --autocomplete-border-color: var(--grey-4);

  --diff-list-added-color: var(--green-4);
  --diff-list-removed-color: var(--red-4);
  --diff-list-obsolete-added-color: var(--green-6);
  --diff-list-obsolete-removed-color: var(--red-6);

  --wiki-page-versions-diff-del-background: var(--red-8);
  --wiki-page-versions-diff-ins-background: var(--green-8);

  --post-notice-border-color: var(--grey-7);
  --post-parent-notice-background: var(--green-8);
  --post-child-notice-background: var(--yellow-8);
  --post-pending-notice-background: var(--blue-8);
  --post-banned-notice-background: var(--red-8);
  --post-deleted-notice-background: var(--red-8);
  --post-resized-notice-background: var(--purple-8);
  --post-search-notice-background: var(--grey-8);

  --post-artist-commentary-container-background: var(--grey-8);
  --post-artist-commentary-container-border-color: var(--grey-7);

  --post-upvote-color: var(--link-color);
  --post-downvote-color: var(--red-4);

  --unsaved-note-box-border-color: var(--red-5);
  --movable-note-box-border-color: var(--blue-5);
  --note-preview-border-color: var(--red-5);
  --note-highlight-color: var(--blue-5);

  --series-pool-color: var(--copyright-tag-color);
  --series-pool-hover-color: var(--copyright-tag-hover-color);

  --collection-pool-color: var(--general-tag-color);
  --collection-pool-hover-color: var(--general-tag-hover-color);

  --fetch-source-data-border-color: var(--grey-4);

  --post-mode-menu-active-post-outline-color: var(--azure-4);
  --post-mode-menu-translation-background: var(--blue-8);

  --low-post-count-color: var(--red-4);

  --remove-favorite-button: var(--red-4);

  --ugoira-seek-slider-background: var(--grey-2);
  --ugoira-seek-slider-progressbar-background: var(--grey-0);

  --keyboard-shortcut-color: var(--grey-0);
  --keyboard-shortcut-background-color: var(--grey-7);

  --login-link-color: var(--red-4);
  --footer-border-color: var(--grey-7);
  --divider-border-color: var(--grey-7);

  --jquery-ui-widget-content-text-color: var(--text-color);
  --jquery-ui-widget-content-background: var(--grey-8);

  --notice-error-background: var(--red-8);
  --notice-error-border-color: var(--red-7);
  --notice-info-background: var(--azure-8);
  --notice-info-border-color: var(--azure-6);

  --dtext-blockquote-border-color: var(--grey-4);
  --dtext-code-background: var(--grey-8);
  --dtext-expand-border: 1px inset var(--grey-4);
  --dtext-spoiler-background-color: black;
  --dtext-spoiler-hover-color: var(--grey-0);

  --wiki-page-other-name-background-color: var(--grey-8);

  --paginator-arrow-background-color: var(--grey-0);
  --paginator-arrow-color: var(--link-color);

  --artist-tag-color: var(--red-3);
  --artist-tag-hover-color: var(--red-2);
  --copyright-tag-color: var(--purple-3);
  --copyright-tag-hover-color: var(--purple-2);
  --character-tag-color: var(--green-3);
  --character-tag-hover-color: var(--green-2);
  --general-tag-color: var(--azure-4);
  --general-tag-hover-color: var(--azure-3);
  --meta-tag-color: var(--yellow-2);
  --meta-tag-hover-color: var(--yellow-1);

  --user-admin-color: var(--artist-tag-color);
  --user-moderator-color: var(--character-tag-color);
  --user-builder-color: var(--copyright-tag-color);
  --user-platinum-color: var(--grey-3);
  --user-gold-color: var(--meta-tag-color);
  --user-member-color: var(--general-tag-color);
  --user-restricted-color: var(--general-tag-color);
  --user-banned-color: var(--grey-6);

  --user-verified-email-color: var(--green-3);
  --user-unverified-email-color: var(--yellow-2);

  --news-updates-background: var(--grey-7);
  --news-updates-border-color: var(--grey-6);

  --related-tags-container-background: var(--grey-8);
  --selected-related-tag-background-color: var(--link-color);
  --selected-related-tag-color: var(--inverse-text-color);

  --inactive-artist-url-color: var(--red-4);
  --inactive-artist-url-hover-color: var(--red-3);
  --banned-artist-label-color: var(--red-5);
  --deleted-artist-label-color: var(--grey-6);
}

body[data-current-user-theme="dark"] {
  @include dark-theme;
}

@media (prefers-color-scheme: dark) {
  body {
    @include dark-theme;
  }
}

